export enum AppRoute {
	ACCESS = 'access',
	ACTIVE = 'active',
	ADVERTISEMENTS = 'advertisements',
	API_KEYS = 'api-keys',
	ARCHIVED = 'archived',
	ARCHIVED_PROJECTS = 'archived-projects',
	AUTH = 'auth',
	COMPANIES = 'companies',
	CREATE = 'create',
	DEFAULT = 'default',
	DIRECTORIES = 'directories',
	EDIT = 'edit',
	FILE_HISTORY = 'file-history',
	FILE_VIEWER = 'view',
	FORGOT_PASSWORD = 'forgot-password',
	INACTIVE = 'inactive',
	LIST = 'list',
	LOGIN = 'login',
	LOGS = 'logs',
	OFFICE = 'office',
	ORDERS_LIST = 'orders-list',
	PDF = 'pdf',
	PDF_TRON = 'pdf-tron',
	PRINT_JOBS = 'print-jobs',
	PROFILE = 'profile',
	PROJECT = 'project',
	PROJECTS = `${AppRoute.PROJECT}s`,
	REPORTS = 'reports',
	SEND_DATA = 'send-data',
	SETTINGS = 'settings',
	USER_ROLES = 'user-roles',
	USERS = 'users',
}
